import { AdditionalInfoBasedFilters } from "./AdditionalInfoBasedFilters";
import { NavigationBasedFilters } from "./NavigationBasedFilters";

import { messages } from "../../constants/messages";

import { getParentElements } from "../../helpers/getParentElements";

/**
 * Component that renders the search popup filter block
 *
 * @param {object} props the data given to the fuction to render the search popup filters
 * @param {string} props.query the query for which the product are being listed
 * @param {array} props.data the data for rendering the filters
 * @param {"autocomplete" | "search"} props.requestType the data for rendering the filters
 */
export const RenderAutocompleteFilters = ({ query, data, requestType }) => {
  const { autocompleteFiltersContianer: filterContainer } = getParentElements();

  filterContainer.innerText = "";

  if (data?.length > 0 || data !== null) {
    if (requestType === "autocomplete") {
      filterContainer.appendChild(AdditionalInfoBasedFilters({ query, data }));
    } else {
      filterContainer.appendChild(NavigationBasedFilters({ query, data }));
    }
  } else {
    filterContainer.appendChild(messages.noFiltersFound({ query }));
  }
};
