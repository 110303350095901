import { Suggestions } from "./Suggestions";

import { getParentElements } from "../../helpers/getParentElements";
import { getParentElementsMobile } from "../../helpers/getParentElementsMobile";

/**
 * Component that renders the search popup suggestions block
 *
 * @param {object} props the data given to the fuction to render the search popup suggestions
 * @param {string} props.query the query for which the suggestions are being listed
 * @param {array} props.data the data for rendering the suggestions
 * @param {boolean} props.isMobile for conditional rendering of filter block
 */
export const RenderAutocompleteSuggestions = ({ query, data, isMobile }) => {
  const { autocompleteSuggestionsContianer: suggestionsContainer } =
    getParentElements();
  const { autocompleteSuggestionsContianer: suggestionsContainerMobile } =
    getParentElementsMobile();

  if (data.length > 0) {
    if (!isMobile) {
      suggestionsContainer.innerText = "";
      suggestionsContainer.style.display = "block";
      suggestionsContainer.appendChild(Suggestions({ data, isMobile }));
    } else {
      suggestionsContainerMobile.innerText = "";
      suggestionsContainerMobile.style.display = "block";
      suggestionsContainerMobile.appendChild(Suggestions({ data, isMobile }));
    }
  }
};
