export const getParentElementsMobile = () => {
  const popupContianer = document.getElementById(
    "gb-searchbar-mobile-popup-container"
  );
  const autocompleteSuggestionsContianer = document.getElementById(
    "gb-searchbar-mobile-autocomplete-suggestions-container"
  );
  const autocompleteFiltersContianer = document.getElementById(
    "gb-searchbar-mobile-filters-container"
  );
  const popupProductsContianer = document.getElementById(
    "gb-searchbar-mobile-products-container"
  );

  return {
    popupContianer,
    autocompleteSuggestionsContianer,
    autocompleteFiltersContianer,
    popupProductsContianer,
  };
};
