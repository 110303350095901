/**@jsx myJSXConverter */
import myJSXConverter from "../../myJSXConverter";

import "./loader.css";

export const loader = () => {
  return (
    <div id="gb-loader-popup" className="gb-loader-popup">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
