export const useFilters = ({ query }) => {
  const navigationLink = ({ type, value }) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.pathname = "/search";
    currentUrl.searchParams.set("q", query);
    currentUrl.searchParams.delete("page");
    currentUrl.hash = `#/filter:${type}:${value}`;

    // Add or update the query parameter

    history.pushState(null, null, currentUrl.href);

    // In order to indicate the change to the browser we dispatch and event
    const changeEvent = new Event("hashchange");
    window.dispatchEvent(changeEvent);

    window.location.reload();
  };

  function replaceSpecialCharacters(inputString) {
    // Define the regex pattern to match special characters and whitespace
    const regex = /[^\w-]/g;

    // Replace special characters and whitespace with "-"
    return inputString.toLowerCase().replace(regex, "-");
  }

  return {
    navigationLink,
    replaceSpecialCharacters,
  };
};
