export const getParentElements = () => {
  const popupContianer = document.getElementById(
    "gb-searchbar-desktop-popup-container"
  );
  const autocompleteSuggestionsContianer = document.getElementById(
    "gb-searchbar-desktop-autocomplete-suggestions-container"
  );
  const autocompleteFiltersContianer = document.getElementById(
    "gb-searchbar-desktop-filters-container"
  );
  const popupProductsContianer = document.getElementById(
    "gb-searchbar-desktop-products-container"
  );

  return {
    popupContianer,
    autocompleteSuggestionsContianer,
    autocompleteFiltersContianer,
    popupProductsContianer,
  };
};
