/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

import { RenderAutocompleteFilters } from "../AutocompleteFilters";
import { RenderPopupProducts } from "../PopupProducts";

import { useAutocompleteSuggestions } from "./useAutocompleteSuggestions";

import { suggestionHover } from "../../helpers/suggestionHover";

import "./AutocompleteSuggestions.css";

/**
 * Component for rendering the autocomplete suggestion returned from the autocomplete api
 *
 * @param {object} props data for rendering the search suggestions
 * @param {array} props.data the data recived from the autocomplete search suggestions
 * @param {boolean} props.isMobile for conditional rendering of filter block
 *
 * @returns the autocomplete suggestions UI
 */
export const Suggestions = ({ data, isMobile }) => {
  const { showSearchListing } = useAutocompleteSuggestions();

  return (
    <div>
      <ul className="gb-searchbar-suggestions-list">
        {data?.map((item, index) => {
          return (
            <li
              className={`gb-searchbar-suggestions-listitem ${
                index === 0 && window.innerWidth > 768
                  ? "active-suggestion"
                  : null
              }`}
              data-value={item?.value}
              onClick={() =>
                showSearchListing({ searchTerm: item?.value.trim(), isMobile })
              }
              id={`gb-searchbar-suggestions-listitem-${index}`}
              customHover={() => {
                const { isActive, setActiveSuggestion } = suggestionHover({
                  index,
                });

                if (!isActive) {
                  if (!isMobile) {
                    RenderAutocompleteFilters({
                      query: item?.value.trim(),
                      data: item?.additionalInfo,
                      requestType: "autocomplete",
                    });
                  }
                  RenderPopupProducts({
                    query: item?.value,
                    isSkuSearch: false,
                    perPage: isMobile ? 2 : 6,
                    isMobile,
                  });

                  setActiveSuggestion();
                }
              }}
            >
              <span className="gb-searchbar-suggestions-listitem-text">
                {item?.value}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
