export const suggestionHover = ({ index }) => {
  const setActiveSuggestion = () => {
    const elem = document.getElementsByClassName("active-suggestion");
    Array.from(elem).forEach((item) => {
      item?.classList?.remove("active-suggestion");
    });

    const currentElem = document.getElementById(
      `gb-searchbar-suggestions-listitem-${index}`
    );

    currentElem?.classList?.add("active-suggestion");
  };

  const isActive = document
    .getElementById(`gb-searchbar-suggestions-listitem-${index}`)
    ?.classList?.contains("active-suggestion");

  return {
    isActive,
    setActiveSuggestion,
  };
};
