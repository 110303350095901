import { getParentElements } from "../../helpers/getParentElements";

export const useProducts = () => {
  const navigationToListing = ({ searchTerm }) => {
    const { popupContianer: autoCompletePopup } = getParentElements();
    const currentUrl = new URL(window.location.href);

    // Add or update the query parameter
    currentUrl.pathname = "/search";
    currentUrl.searchParams.set("q", searchTerm);
    currentUrl.searchParams.delete("page");
    currentUrl.searchParams.delete("perpage");
    currentUrl.hash = "";

    history.pushState(null, null, currentUrl.href);

    // In order to indicate the change to the browser we dispatch and event
    const changeEvent = new Event("hashchange");
    window.dispatchEvent(changeEvent);

    autoCompletePopup.style.display = "none";

    const searchbar = document.getElementById("gb-searchbar-desktop-input");
    searchbar.value = searchTerm;

    window.location.reload();
  };

  return {
    navigationToListing,
  };
};
