/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

import { useSearch } from "./useSearch";

import "./Search.css";

/**
 * Component reponsible for rendering the basic building blocks for the search popup
 *
 * @returns JSX component
 */
export const SearchBar = () => {
  const { defaultValue, onKeyUp, onSubmit } = useSearch();

  return (
    <div className="gb-searchbar-desktop-wrapper">
      <form
        id="gb-searchbar-desktop-form"
        className="gb-searchbar-desktop-form"
        onSubmit={(e) => onSubmit(e)}
      >
        <input
          id="gb-searchbar-desktop-input"
          name="searchTerm"
          type="text"
          placeholder="Search Model# or Keyword"
          value={defaultValue()}
          onKeyUp={(e) => onKeyUp(e)}
        />
        <button
          type="submit"
          className="gb-searchbar-desktop-form-submit-btn"
          aria-label="search"
        ></button>
      </form>
      <div id="gb-searchbar-desktop-popup-container" style="display: none;">
        <div
          id="gb-searchbar-desktop-autocomplete-suggestions-container"
          className="gb-searchbar-desktop-autocomplete-suggestions-container"
          style="display:none;"
        ></div>
        <div
          id="gb-searchbar-desktop-filters-container"
          className="gb-searchbar-desktop-filters-container"
        ></div>
        <div
          id="gb-searchbar-desktop-products-container"
          className="gb-searchbar-desktop-products-container"
        ></div>
      </div>
    </div>
  );
};
