import { getKeys } from "../constants/getKeys";

/**
 * This is the instance of class to make an api call
 * Here creating an instance was a better option because we need to use the headers everywhere
 */
class fetchClass {
  constructor() {
    this.keys = getKeys();
    this.defaultHeaders = new Headers();
    this.defaultHeaders.append(
      "Authorization",
      `client-key ${this.keys["client-key"]}`
    );
    this.defaultHeaders.append("Content-Type", "application/json");
    this.defaultHeaders.append(
      "X-Groupby-Customer-ID",
      this.keys["X-Groupby-Customer-ID"]
    );
  }

  async get({ url, headers = {}, data }) {
    const response = await fetch(url, {
      method: "GET",
      headers: this.defaultHeaders,
      body: JSON.stringify(data),
    });

    return await response.json();
  }

  async post({ url, headers = {}, data }) {
    const response = await fetch(url, {
      method: "POST",
      headers: this.defaultHeaders,
      body: JSON.stringify(data),
    });

    return await response.json();
  }

  async postFormData({ url, headers = {}, data }) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: data.toString(),
    });

    return await response.json();
  }
}

// Here this class is initiated and along with that we can access its methods for making the api call
export const fetchInstance = new fetchClass();
