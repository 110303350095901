import { triggerHashChange } from "../../helpers/hashChange";

export const useAutocompleteSuggestions = () => {
  const showSearchListing = async ({ searchTerm, isMobile }) => {
    const currentUrl = new URL(window.location.href);

    // Add or update the query parameter
    currentUrl.pathname = "/search";
    currentUrl.hash = "";
    currentUrl.searchParams.set("q", searchTerm);

    history.pushState(null, null, currentUrl.href);

    triggerHashChange();

    const autocompletePopup = document.getElementById(
      `gb-searchbar-${isMobile ? "mobile" : "desktop"}-popup-container`
    );
    const searchbar = document.getElementById(
      `gb-searchbar-${isMobile ? "mobile" : "desktop"}-input`
    );
    
    autocompletePopup.style.display = "none";
    searchbar.value = searchTerm;

    window.location.reload();
  };

  return {
    showSearchListing,
  };
};
