/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

import { RenderPopupProducts } from "../PopupProducts";

import { useFilters } from "./useFilters";

import { suggestionFilterHover } from "../../helpers/suggestionFilterHover";

/**
 * Component for rendering the search bar's filter section if it is a direct sku search
 *
 * @param {object} props the data for the component to render
 * @param {string} props.query the sku search suggestion value for the given filters
 * @param {array} props.data the sku search suggestion based filters
 *
 * @returns search popup filters UI
 */
export const NavigationBasedFilters = ({ query, data }) => {
  const { navigationLink } = useFilters({ query });

  return (
    <div className="gb-searchbar-desktop-navigation-based-filters">
      {data.map((navigation) => {
        if (navigation?.name === "attributes.t_category") {
          return (
            <div className="gb-searchbar-desktop-filters-categories-block">
              <span className="gb-searchbar-desktop-filters-categories-block-title">
                {navigation?.displayName}
              </span>
              <ul className="gb-searchbar-desktop-filters-categories-block-list">
                {navigation?.refinements?.slice(0, 5)?.map((item, index) => {
                  return (
                    <li
                      id={`gb-searchbar-desktop-filters-categories-block-listitem-${index}`}
                      className="gb-searchbar-desktop-filters-categories-block-listitem"
                      type="category"
                      onClick={() =>
                        navigationLink({
                          type: navigation?.name,
                          value: item.value.trim(),
                        })
                      }
                      customHover={() => {
                        const { isActive, setActiveSuggestionFilter } =
                          suggestionFilterHover({ index, type: "categories" });
                        if (!isActive) {
                          setActiveSuggestionFilter();
                          RenderPopupProducts({
                            query,
                            refinements: [
                              {
                                type: "Value",
                                navigationName: navigation?.name,
                                value: item?.value?.trim(),
                                or: true,
                              },
                            ],
                            isSkuSearch: false,
                          });
                        }
                      }}
                    >
                      <span className="gb-searchbar-desktop-filters-categories-block-listitem-label">
                        {navigation.name !== "attributes.gbi_price"
                          ? item?.value.replaceAll("-", " ")
                          : item?.value}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        } else if (navigation?.name === "brands") {
          return (
            <div className="gb-searchbar-desktop-filters-brands-block">
              <span className="gb-searchbar-desktop-filters-brands-block-title">
                {navigation?.displayName}
              </span>
              <ul className="gb-searchbar-desktop-filters-brands-block-list">
                {navigation?.refinements?.slice(0, 5)?.map((item, index) => {
                  return (
                    <li
                      id={`gb-searchbar-desktop-filters-brands-block-listitem-${index}`}
                      className="gb-searchbar-desktop-filters-brands-block-listitem"
                      type="brands"
                      onClick={() =>
                        navigationLink({
                          type: navigation?.name,
                          value: item?.value.trim(),
                        })
                      }
                      customHover={() => {
                        const { isActive, setActiveSuggestionFilter } =
                          suggestionFilterHover({ index, type: "brands" });
                        if (!isActive) {
                          RenderPopupProducts({
                            query,
                            refinements: [
                              {
                                type: "Value",
                                navigationName: navigation?.name,
                                value: item?.value.trim(),
                                or: true,
                              },
                            ],
                            isSkuSearch: false,
                          });
                          setActiveSuggestionFilter();
                        }
                      }}
                    >
                      <span className="gb-searchbar-desktop-filters-brands-block-listitem-label">
                        {navigation.name !== "attributes.gbi_price"
                          ? item?.value.replaceAll("-", " ")
                          : item?.value}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        } else if (navigation?.name === "attributes.gbi_price") {
          return (
            <div className="gb-searchbar-desktop-filters-price-block">
              <span className="gb-searchbar-desktop-filters-price-block-title">
                {navigation?.displayName}
              </span>
              <ul className="gb-searchbar-desktop-filters-price-block-list">
                {navigation?.refinements?.slice(0, 5)?.map((item, index) => {
                  return (
                    <li
                      id={`gb-searchbar-desktop-filters-price-block-listitem-${index}`}
                      className="gb-searchbar-desktop-filters-price-block-listitem"
                      type="price"
                      onClick={() =>
                        navigationLink({
                          type: navigation?.name,
                          value: item?.value.trim(),
                        })
                      }
                      customHover={() => {
                        const { isActive, setActiveSuggestionFilter } =
                          suggestionFilterHover({ index, type: "price" });
                        if (!isActive) {
                          RenderPopupProducts({
                            query,
                            refinements: [
                              {
                                type: "Value",
                                navigationName: navigation?.name,
                                value: item?.value.trim(),
                                or: true,
                              },
                            ],
                            isSkuSearch: false,
                          });
                          setActiveSuggestionFilter();
                        }
                      }}
                    >
                      <span className="gb-searchbar-desktop-filters-price-block-listitem-label">
                        {navigation.name !== "attributes.gbi_price"
                          ? item?.value.replaceAll("-", " ")
                          : item?.value}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
