/**
 * Dynamic function for sending the beacons
 *
 * @param {object} props these are the props that send different beacons on the basis of the props passed
 * @param {string} props.requestId it is the id received from the products search request
 * @param {boolean} props.sayt it should be true for the search bar beacons
 * @param {boolean} props.search it should be true for the search listing page
 * @param {boolean} props.navigation it should be true for the category listing page
 */
export const sendBeacon = ({ requestId, sayt, search, navigation }) => {
  if (window?.GbTracker) {
    gbTracker?.sendAutoSearchEvent({
      search: {
        id: requestId,
        origin: {
          search,
          sayt,
          navigation,
          dym: false,
          recommendations: false,
          autosearch: false,
          collectionSwitcher: false,
        },
      },
    });
  }
};
