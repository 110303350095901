import { RenderAutocompleteFilters } from "../../components/AutocompleteFilters";
import { RenderAutocompleteSuggestions } from "../../components/AutocompleteSuggestions";
import { RenderPopupProducts } from "../../components/PopupProducts";

import { autocompleteApi } from "../../services/autocompleteApi";

import { getParentElements } from "../../helpers/getParentElements";
import { triggerHashChange } from "../../helpers/hashChange";
import { removeSpecialChars } from "../../helpers/sanitizeSearchQuery";

export const useSearch = () => {
  const closePopup = () => {
    const {
      popupContianer,
      autocompleteSuggestionsContianer,
      autocompleteFiltersContianer,
      popupProductsContianer,
    } = getParentElements();

    autocompleteSuggestionsContianer.innerText = "";
    autocompleteFiltersContianer.innerText = "";
    popupProductsContianer.innerText = "";

    if (popupContianer) popupContianer.style.display = "none";
  };
  document.addEventListener("click", () => closePopup());

  const defaultValue = () => {
    //default value of search bar
    const url = new URL(window.location.href);
    const queryParam = url.searchParams.get("q");

    if (queryParam) {
      return queryParam;
    } else {
      return "";
    }
  };

  const onKeyUp = async (e) => {
    const {
      popupContianer,
      autocompleteSuggestionsContianer,
      autocompleteFiltersContianer,
      popupProductsContianer,
    } = getParentElements();

    if (e.key !== "Enter" && e.target.value.length >= 2) {
      popupContianer.style.display = "flex";
      popupContianer.style.justifyContent = "space-between";

      const query = removeSpecialChars(e.target.value);

      const { searchTerms } = await autocompleteApi({ query });

      const previousSuggestion = document
        .getElementById("gb-searchbar-suggestions-listitem-0")
        ?.getAttribute("data-value");

      if (searchTerms && searchTerms.length > 0) {
        if (previousSuggestion !== searchTerms[0].value) {
          RenderAutocompleteSuggestions({
            query,
            data: searchTerms,
            isMobile: false,
          });

          RenderAutocompleteFilters({
            query: searchTerms[0]?.value,
            data: searchTerms[0]?.additionalInfo,
            requestType: "autocomplete",
          });

          RenderPopupProducts({
            query: searchTerms[0]?.value,
            perPage: 6,
            isSkuSearch: false,
            isMobile: false,
          });
        }
      } else {
        // if on previous search there were suggestion it will not be hidden if the next search request is a sku request
        autocompleteSuggestionsContianer.style.display = "none";
        RenderPopupProducts({
          query,
          perPage: 6,
          isSkuSearch: true,
          isMobile: false,
        });
      }
    } else {
      autocompleteSuggestionsContianer.innerText = "";
      autocompleteFiltersContianer.innerText = "";
      popupProductsContianer.innerText = "";

      popupContianer.style.display = "none";
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      popupContianer,
      autocompleteSuggestionsContianer,
      autocompleteFiltersContianer,
      popupProductsContianer,
    } = getParentElements();

    const data = new FormData(e.target);
    const searchTerm = removeSpecialChars(data.get("searchTerm")?.trim());

    if (searchTerm.length >= 2) {
      const currentUrl = new URL(window.location.href);

      // Add or update the query parameter
      currentUrl.pathname = "/search";
      currentUrl.searchParams.set("q", searchTerm);
      currentUrl.searchParams.delete("page");
      currentUrl.searchParams.delete("perpage");
      currentUrl.hash = "";

      history.pushState(null, null, currentUrl.href);

      triggerHashChange();

      autocompleteSuggestionsContianer.innerText = "";
      autocompleteFiltersContianer.innerText = "";
      popupProductsContianer.innerText = "";

      popupContianer.style.display = "none";

      window.location.reload();
    }
  };

  return {
    defaultValue,
    closePopup,
    onKeyUp,
    onSubmit,
  };
};
