export const suggestionFilterHover = ({ index, type }) => {
  const setActiveSuggestionFilter = () => {
    const elem = document.getElementsByClassName("active-suggestion-filter");
    Array.from(elem).forEach((item) => {
      item?.classList?.remove("active-suggestion-filter");
    });

    const currentElem = document.getElementById(
      `gb-searchbar-desktop-filters-${type}-block-listitem-${index}`
    );

    currentElem?.classList?.add("active-suggestion-filter");
  };

  const isActive = document
    .getElementById(
      `gb-searchbar-desktop-filters-${type}-block-listitem-${index}`
    )
    ?.classList?.contains("active-suggestion-filter");

  return {
    isActive,
    setActiveSuggestionFilter,
  };
};
