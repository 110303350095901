import { PopupProducts } from "./Products";
import { RenderAutocompleteFilters } from "../AutocompleteFilters";
import { loader } from "../PopupLoader";

import { searchApi } from "../../services/searchApi";

import { getParentElements } from "../../helpers/getParentElements";
import { getParentElementsMobile } from "../../helpers/getParentElementsMobile";
import { sendBeacon } from "../../helpers/beacons";

import "./PopupProducts.css";

/**
 * Component that renders the search popup product block
 *
 * @param {object} props the data given to the fuction to render the search popup product
 * @param {string} props.query the query for which the products are being listed
 * @param {number} props.perPage the number of product that will be returned
 * @param {array} props.refinements the user facing filter for listing the products based on user interaction
 * @param {boolean} props.isSkuSearch to determine that the given search is a direct sku search or normal search
 * @param {boolean} props.isMobile for conditionally rendering the filter block
 */
export const RenderPopupProducts = async ({
  query,
  perPage = 6,
  refinements = [],
  isSkuSearch = true,
  isMobile = false,
}) => {
  const { popupProductsContianer: productsContainer } = getParentElements();
  const { popupProductsContianer: productsContainerMobile } =
    getParentElementsMobile();

  if (isMobile) {
    productsContainerMobile.innerText = "";
    productsContainerMobile.appendChild(loader());
  } else {
    productsContainer.innerText = "";
    productsContainer.appendChild(loader());
  }

  const { id, records, totalRecordCount, availableNavigation } =
    await searchApi({
      query,
      perPage,
      refinements,
      pageType: "search",
    });

  if (isSkuSearch && !isMobile) {
    RenderAutocompleteFilters({
      data: availableNavigation,
      query,
      requestType: "search",
    });
  }

  if (!isMobile) {
    productsContainer.innerText = "";
    productsContainer.appendChild(
      PopupProducts({ data: records, totalRecordCount, query })
    );
  } else {
    productsContainerMobile.innerText = "";
    productsContainerMobile.appendChild(
      PopupProducts({ data: records, totalRecordCount, query })
    );
  }

  sendBeacon({ requestId: id, sayt: true, search: false, navigation: false });
};
