/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

export const useProductCard = () => {
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const renderVariantPrices = (variants) => {
    const allPrices = variants.map((item) => {
      if (item?.priceInfo?.price < item?.priceInfo?.originalPrice) {
        return item?.priceInfo?.price;
      } else {
        return item?.priceInfo?.originalPrice;
      }
    });

    let smallestOne;
    for (let i = 0; i < allPrices.length; i++) {
      if (i === 0) {
        smallestOne = allPrices[i];
      } else if (allPrices[i] < smallestOne) {
        smallestOne = allPrices[i];
      }
    }

    return (
      <span class="ktpl_item-price">
        {smallestOne ? `$${numberWithCommas(smallestOne?.toFixed(2))}` : null}
      </span>
    );
  };

  const renderNormalPrices = (priceInfo) => {
    if (!priceInfo || Object.keys(priceInfo).length === 0) {
      return <span class="ktpl_item-price"></span>;
    } else {
      return (
        <span class="ktpl_item-price">
          ${numberWithCommas(priceInfo?.price?.toFixed(2))}
        </span>
      );
    }
  };

  return {
    renderNormalPrices,
    renderVariantPrices,
  };
};
