/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

import { PopupProductCard } from "../PopupProductCard";

import { useProducts } from "./useProducts";

/**
 * Component responsible for rendering the product listing in the search popup
 *
 * @param {object} props the props required to render the product listing of popup
 * @param {string} props.query the qeury for which the products are being listed
 * @param {array} props.data the of the product returned by the group by
 * @param {number} props.totalRecordCount the total number of the products returned
 *
 * @returns the product listing block in the search popup
 */
export const PopupProducts = ({ query, data, totalRecordCount }) => {
  const { navigationToListing } = useProducts();

  return (
    <div className="gb-searchbar-product-block-wrapper">
      <div className="gb-searchbar-product-block">
        <strong className="gb-searchbar-product-block-title">
          Product Suggestions
        </strong>
        <div className="gb-searchbar-product-block-list">
          {data?.map((item) => {
            return PopupProductCard({ item });
          })}
        </div>
      </div>
      <div
        className="gb-searchbar-product-block-footer"
        id="gb-searchbar-product-block-footer"
      >
        <span
          className="gb-searchbar-product-block-all-results"
          onClick={() => navigationToListing({ searchTerm: query })}
        >
          See {totalRecordCount} results "{query}"
        </span>
      </div>
    </div>
  );
};
