/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

import { RenderPopupProducts } from "../PopupProducts";

import { useFilters } from "./useFilters";

import { suggestionFilterHover } from "../../helpers/suggestionFilterHover";

import "./AutocompleteFilters.css";

/**
 * Component for rendering the search bar's filter section if we get the response from autocomplete api
 *
 * @param {object} props the data for the component to render
 * @param {string} props.query the autocomplete search suggestion value for the given filters
 * @param {object} props.data the autocomplete search suggestion based filters
 *
 * @returns search popup filters UI
 */
export const AdditionalInfoBasedFilters = ({ query, data }) => {
  const { navigationLink, replaceSpecialCharacters } = useFilters({ query });
  const navKeys = Object.keys(data);

  return (
    <div className="gb-searchbar-desktop-additionalinfo-based-filters">
      {navKeys.map((key) => {
        if (key === "categories") {
          const allNavData = data[key];
          const filteredNavData = allNavData.filter((item) =>
            item.includes(">")
          );
          const finalNavData = filteredNavData.map((item) => {
            const result = item.split(">");
            return result[result.length - 1];
          });

          return (
            <div className="gb-searchbar-desktop-filters-categories-block">
              <span className="gb-searchbar-desktop-filters-categories-block-title">
                {key}
              </span>
              <ul className="gb-searchbar-desktop-filters-categories-block-list">
                {finalNavData?.slice(0, 5)?.map((item, index) => {
                  return (
                    <li
                      className="gb-searchbar-desktop-filters-categories-block-listitem"
                      onClick={() =>
                        navigationLink({
                          type: "attributes.t_category",
                          value: replaceSpecialCharacters(item?.trim()),
                        })
                      }
                      type="category"
                      id={`gb-searchbar-desktop-filters-categories-block-listitem-${index}`}
                      customHover={() => {
                        const { isActive, setActiveSuggestionFilter } =
                          suggestionFilterHover({ index, type: "categories" });
                        if (!isActive) {
                          RenderPopupProducts({
                            query,
                            refinements: [
                              {
                                type: "Value",
                                navigationName: "attributes.t_category",
                                value: replaceSpecialCharacters(item?.trim()),
                                or: true,
                              },
                            ],
                            isSkuSearch: false,
                            isMobile: false,
                            perPage: 6,
                          });
                          setActiveSuggestionFilter();
                        }
                      }}
                    >
                      <span className="gb-searchbar-desktop-filters-categories-block-listitem-label">
                        {item?.replaceAll("-", " ")}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        } else if (key === "brands") {
          return (
            <div className="gb-searchbar-desktop-filters-brands-block">
              <span className="gb-searchbar-desktop-filters-brands-block-title">
                {key}
              </span>
              <ul className="gb-searchbar-desktop-filters-brands-block-list">
                {data[key]?.slice(0, 5)?.map((item, index) => {
                  return (
                    <li
                      className="gb-searchbar-desktop-filters-brands-block-listitem"
                      onClick={() =>
                        navigationLink({
                          type: "brands",
                          value: item?.trim(),
                        })
                      }
                      type="brands"
                      id={`gb-searchbar-desktop-filters-brands-block-listitem-${index}`}
                      customHover={() => {
                        const { isActive, setActiveSuggestionFilter } =
                          suggestionFilterHover({ index, type: "brands" });
                        if (!isActive) {
                          RenderPopupProducts({
                            query,
                            refinements: [
                              {
                                type: "Value",
                                navigationName: key,
                                value: item?.trim(),
                                or: true,
                              },
                            ],
                            isSkuSearch: false,
                          });
                          setActiveSuggestionFilter();
                        }
                      }}
                    >
                      <span className="gb-searchbar-desktop-filters-brands-block-listitem-label">
                        {item?.replaceAll("-", " ")}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        }
      })}
    </div>
  );
};
