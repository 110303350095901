import { fetchInstance } from "./fetchInstance";
import { getKeys } from "../constants/getKeys";

export const autocompleteApi = async ({ query }) => {
  try {
    const keys = getKeys();
    const loginId = localStorage?.getItem("c_id");
    const visitorId = document?.cookie
      ?.split("gbi_visitorId=")[1]
      ?.split(";")[0];
    if (loginId) {
      const data = await fetchInstance.get({
        url: `https://autocomplete.${keys["X-Groupby-Customer-ID"]}.groupbycloud.com/api/request?collection=${keys.collection}&area=${keys.area}&searchItems=4&query=${query}&visitorId=${visitorId}&loginId=${loginId}`,
      });

      return data;
    } else {
      const data = await fetchInstance.get({
        url: `https://autocomplete.${keys["X-Groupby-Customer-ID"]}.groupbycloud.com/api/request?collection=${keys.collection}&area=${keys.area}&searchItems=4&query=${query}&visitorId=${visitorId}`,
      });

      return data;
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: autocompleteApi.js:5 ~ autocompleteApi ~ error:",
      error
    );
    return error;
  }
};
