export const getKeys = () => {
  const url = new URL(window.location.href);
  const hostname = url.hostname;

  if (hostname.includes("www")) {
    return {
      collection: "MBprod",
      area: "MBprod",
      "X-Groupby-Customer-ID": "spiral",
      "client-key": "7917ec75-cb89-4330-8830-6331220d707b",
    };
  } else if (hostname.includes("staging")) {
    return {
      collection: "MBstaging",
      area: "MBstaging",
      "X-Groupby-Customer-ID": "spiral",
      "client-key": "7917ec75-cb89-4330-8830-6331220d707b",
    };
  } else if (hostname.includes("dev")) {
    return {
      collection: "MBdev",
      area: "MBdev",
      "X-Groupby-Customer-ID": "spiral",
      "client-key": "7917ec75-cb89-4330-8830-6331220d707b",
    };
  } else {
    return {
      collection: "MBdev",
      area: "MBdev",
      "X-Groupby-Customer-ID": "spiral",
      "client-key": "7917ec75-cb89-4330-8830-6331220d707b",
    };
  }
};
