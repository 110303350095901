// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gb-searchbar-product-block-list {
  display: flex;
  flex-wrap: wrap;
}

.gb-searchbar-product-block-wrapper {
  background-color: white;
  /* display: flex;
  flex-direction: column; */
}

.gb-searchbar-product-block {
  margin: 0 0 -20px 0;
  padding: 20px;
  flex-grow: 1;
}

.gb-searchbar-desktop-products-container {
  flex-grow: 1;
  display: flex;
  background-color: white;
}

.gb-searchbar-product-block-title {
  font-size: 18px;
  margin: 0 0 20px 0;
  display: block;
}

.gb-searchbar-product-block-footer {
  padding: 20px;
  display: flex;
  justify-content: end;
  font-size: 14px;
  cursor: pointer;
  text-align: end;
}

@media only screen and (max-width: 767px) {
  .gb-searchbar-product-block-title {
    display: none;
  }

  .gb-searchbar-product-block-footer {
    justify-content: start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PopupProducts/PopupProducts.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB;2BACyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,oBAAoB;EACpB,eAAe;EACf,eAAe;EACf,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".gb-searchbar-product-block-list {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.gb-searchbar-product-block-wrapper {\n  background-color: white;\n  /* display: flex;\n  flex-direction: column; */\n}\n\n.gb-searchbar-product-block {\n  margin: 0 0 -20px 0;\n  padding: 20px;\n  flex-grow: 1;\n}\n\n.gb-searchbar-desktop-products-container {\n  flex-grow: 1;\n  display: flex;\n  background-color: white;\n}\n\n.gb-searchbar-product-block-title {\n  font-size: 18px;\n  margin: 0 0 20px 0;\n  display: block;\n}\n\n.gb-searchbar-product-block-footer {\n  padding: 20px;\n  display: flex;\n  justify-content: end;\n  font-size: 14px;\n  cursor: pointer;\n  text-align: end;\n}\n\n@media only screen and (max-width: 767px) {\n  .gb-searchbar-product-block-title {\n    display: none;\n  }\n\n  .gb-searchbar-product-block-footer {\n    justify-content: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
