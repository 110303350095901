import { fetchInstance } from "./fetchInstance";
import { getKeys } from "../constants/getKeys";

export const searchApi = async ({
  query = "",
  refinements = [],
  page = 0,
  perPage = 24,
  sorts,
  pageType = "search",
  pageCategories = "",
}) => {
  const keys = getKeys();
  const loginId = localStorage?.getItem("c_id");
  const visitorId = document?.cookie?.split("gbi_visitorId=")[1]?.split(";")[0];
  const defaultRefinements = [
    {
      type: "Value",
      navigationName: "attributes.flags",
      value: pageType === "search" ? "Search" : "Catalog",
      or: true,
    },
    {
      type: "Value",
      navigationName: "attributes.flags",
      value: "Catalog, Search",
      or: true,
    },
  ];
  try {
    const data = await fetchInstance.post({
      url: `https://search.${keys["X-Groupby-Customer-ID"]}.groupbycloud.com/api/search`,
      data: {
        query,
        pageCategories: pageCategories?.length > 0 ? [pageCategories] : "",
        collection: keys.collection,
        area: keys.area,
        visitorId: visitorId ? visitorId : "",
        loginId,
        preFilter: pageCategories?.includes("discontinued-products")
          ? ""
          : '(availability: ANY("IN_STOCK"))',
        pageSize: perPage ? perPage : 24,
        refinements:
          refinements.length > 0
            ? [...defaultRefinements, ...refinements]
            : defaultRefinements,
        skip:
          page === 0 ? 0 : (page - 1) * perPage > 0 ? (page - 1) * perPage : 0,
        sorts: sorts && sorts.length > 0 ? sorts : [],
      },
    });

    return data;
  } catch (error) {
    console.log("🚀 ~ file: searchApi.js:11 ~ searchApi ~ error:", error);
    return error;
  }
};
