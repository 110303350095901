/**@jsx myJSXConverter **/
import myJSXConverter from "../myJSXConverter";

import errorIcon from "../assets/alert-triangle.svg";

export const messages = {
  noSuggestionsFound: ({ query }) => {
    return (
      <div>
        Sorry we coundn't find anything related to {query}, can you search for
        something else
      </div>
    );
  },
  noFiltersFound: ({ query }) => {
    return (
      <div>
        Sorry we coundn't find any filters for <b>{query}</b>
      </div>
    );
  },
  noProductsFound: () => {
    return (
      <div className="gb-listing-no-products-found">
        <img src={errorIcon} alt="warning-icon" />
        <span className="gb-listing-no-products-found-msg">
          We can't find products matching the selection.
        </span>
      </div>
    );
  },
};
