/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

import { useProductCard } from "./useProductCard";

import fallBackImage from "../../assets/fallback-image.png";

import "./PopupProductCard.css";

/**
 * Component responsible for rendering the UI of the search popup's product card
 *
 * @param {object} props data for a single product card in search popup
 * @param {object} props.item product data from returned from groupby
 *
 * @returns UI for a single product
 */
export const PopupProductCard = ({ item }) => {
  const { renderNormalPrices, renderVariantPrices } = useProductCard();

  return (
    <div
      id={item?.allMeta?.attributes?.magento_id?.text[0]}
      class="gb-searchbar-product-block-listitem"
    >
      <a href={item?.allMeta?.uri}>
        <div class="gb-searchbar-product-block-listitem-image">
          <img
            src={item?.allMeta?.images[0]?.uri}
            alt={item?._t}
            onError={`this.src='${fallBackImage}'`}
          />
        </div>
        <div className="gb-searchbar-product-block-listitem-details">
          <p class="gb-searchbar-product-block-listitem-name">{item?._t}</p>

          {item?.allMeta?.attributes?.flags?.text?.includes("Call for Quote")
            ? null
            : item?.allMeta?.attributes?.flags?.text?.includes("Discontinued")
            ? renderNormalPrices(item?.allMeta?.priceInfo)
            : item?.allMeta?.variants
            ? renderVariantPrices(item.allMeta.variants)
            : renderNormalPrices(item?.allMeta?.priceInfo)}
        </div>
      </a>
    </div>
  );
};
